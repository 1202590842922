import {registerLocaleData} from '@angular/common'
import {provideHttpClient} from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import {
  ApplicationConfig,
  inject,
  LOCALE_ID,
  provideAppInitializer,
  provideZoneChangeDetection
} from '@angular/core'
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {
  provideRouter,
  withHashLocation,
  withInMemoryScrolling
} from '@angular/router'
import {routes} from './app.routes'
import {LOCAL_STORAGE, WINDOW} from './application/app'
import {ConfigService} from './services/config.service'

registerLocaleData(localeFr, 'fr')
export const appConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(() => {
      const initializerFn = ((s: ConfigService) => () => s.isLoggedIn())(inject(ConfigService))
      return initializerFn()
    }),
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {width: '90%', maxWidth: '400px'}
    },
    {provide: LOCALE_ID, useValue: 'fr'},
    {provide: WINDOW, useValue: window},
    {
      provide: LOCAL_STORAGE,
      useFactory: () => localStorage
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {
        floatLabel: 'always',
        appearance: 'outline',
        hideRequiredMarker: true
      }
    },
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes, withHashLocation(), withInMemoryScrolling({anchorScrolling: 'enabled'})),
    provideAnimationsAsync(),
    provideHttpClient()
  ]
}