import {inject} from '@angular/core'
import {Router} from '@angular/router'
import {Observable, of} from 'rxjs'
import {ConfigService} from '../services/config.service'
import {WINDOW} from './app'

export const authGuard = (): Observable<boolean | Promise<boolean>> => {
  const router = inject(Router)
  const configService = inject(ConfigService)

  if (typeof configService.getAccessToken() === 'string') {
    return of(true)
  }
  return of(router.navigate(['/foralder']))
}

export const deadGuard = (): Observable<boolean | Promise<boolean>> => {
  const window = inject(WINDOW)

  window.location.href = 'https://internetbank.sparbankensyd.se/kundkannedom'
  return of(false)
}
