import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms'


export const careOfValidator: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
  if(group.value.careOf === '2'){
    return group.value.coAddress.length > 4 ? null : {
      error: {
        message: 'Du måste ange en care of adress.'
      }
    }
  }
  return null
}