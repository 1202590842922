import {Component} from '@angular/core'
import {AdminService} from '../../services/admin.service'
import { MatCard, MatCardContent, MatCardActions } from '@angular/material/card'
import { MatButton } from '@angular/material/button'

@Component({
    selector: 'spb-supervisor',
    templateUrl: './supervisor.component.html',
    styleUrls: ['./supervisor.component.scss'],
    imports: [MatCard, MatCardContent, MatCardActions, MatButton]
})
export class SupervisorComponent {

  constructor(public adminService: AdminService) {
  }
}
