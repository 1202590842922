import {Component, OnInit} from '@angular/core'
import {Router} from '@angular/router'
import {BankIdComponent} from '@sparbanken-syd/sparbanken-syd-bankid'
import {filter, finalize, first, map, switchMap} from 'rxjs/operators'
import {AdminService} from '../../services/admin.service'
import {WaitDialogComponent} from '../wait-dialog/wait-dialog.component'
import {MatDialog} from '@angular/material/dialog'
import {environment} from '../../../environments/environment'
import {ConfigService} from '../../services/config.service'
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component'

@Component({
    selector: 'spb-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
  imports: [BreadcrumbsComponent, BankIdComponent]
})
export class LoginComponent implements OnInit {
  protected readonly environment = environment

  constructor(
    private adminService: AdminService,
    private configService: ConfigService,
    private dialog: MatDialog,
    private router: Router
  ) {
  }

  /**
   * If we are here and bank id submits then trigger fetching,
   * of Admin data. Admin service will have to make sure not to call
   * backends if not needed.
   * When we have data we navigate to /kyc (next)
   */
  public ngOnInit(): void {
    let waitRef: any
    this.configService.bankIdResult
      .pipe(
        filter((res: any) => res.accessToken),
        map(() => waitRef = this.dialog.open(WaitDialogComponent, {
          disableClose: true,
          data: {
            title: 'Vänta',
            text: 'Hämtar inloggningsdata'
          }
        })),
        switchMap(() => this.adminService.fetchAdminData()),
        first(),
        finalize(() => waitRef.close())
      )
      .subscribe({
        next: () => {
          this.router.navigate(['/minderarig']).then()
        }
      })
  }

  public onSuccessLogin(accessToken: string) {
    this.configService.setToken({accessToken})
    this.configService.bankIdResult.next({accessToken})
  }
}